.promoted {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  svg {
    box-shadow: 0px 0px 69px 2px rgba(0, 0, 0, 0.75);
  }
}
