@import '../variables/index.scss';

.projection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 0;
  overflow: auto;
  flex-flow: 1;
}

@media only screen and (max-width: $mobile-viewport) {
  .projection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 0;
    overflow: auto;
  }
}
