@import '../variables/index.scss';

.piccle-result-sync {
  width: 30vw;
  height: auto;
}

@media only screen and (max-width: $desktop-viewport) {
  .piccle-result-sync {
    width: 70vw;
    height: auto;
  }
}
