@import '../variables/viewport.scss';

@media only screen and (max-width: $mobile-viewport) {
  .navbar-logo {
    margin: auto;
    cursor: pointer;
    margin-top: '35px';
    width: 10vw;
  }
}

@media only screen and (min-width: $mobile-viewport) {
  .navbar-logo {
    margin: auto;
    cursor: pointer;
    margin-top: '35px';
    width: 3.5rem;
  }
}

@media only screen and (max-width: 520px) {
  .navbar-links {
    flex-direction: column;
    justify-content: space-around;
    min-width: 60px;
  }
}
