@font-face {
  font-family: 'RivalSlab-Regular';
  src: url('../../assets/fonts/rival-slab-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RivalSlab-Medium';
  src: url('../../assets/fonts/rival-slab-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
