@import '../variables/index.scss';

.async-projection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 0;
  width: 100%;
  height: auto;
  overflow: auto;
}

h2.async-thankyou-header {
  line-height: normal;
  text-align: center;
}

button.async-button {
  background: $piccle-green;
  color: white;
  border-radius: 20px;
  font-family: $font-family;
  font-weight: 500;
}

.async-btn-group {
  width: 30vw;
}

h1.async-welcome-header {
  line-height: normal;
  text-align: center;
}

picture.async-welcome-image {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
}

.center-flex {
  button {
    background-color: $text-color;
    background-image: none;

    :hover {
      background-color: unset;
    }
  }
}

.async-done-btn-container {
  display: flex;
  flex-direction: row-reverse;
}

.piccle-result {
  width: 15%;
  height: auto;
}

.async-end-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  h2 {
    padding-bottom: 5vh;
  }
}

@media only screen and (min-width: $desktop-viewport) {
  h1.async-welcome-header {
    font-size: 1.6rem;
  }

  picture.async-welcome-image {
    width: 50vw;
  }

  h2.async-thankyou-header {
    font-size: 2vw;
  }

  button.async-button {
    font-size: 2.5vw;
  }

  .center-flex {
    width: 30vw;
  }

  .async-end-container {
    picture {
      width: 50vw;
      height: auto;
    }
  }
}

@media only screen and (max-width: $desktop-viewport) {
  h1.async-welcome-header {
    font-size: 1.6rem;
  }

  h2.async-thankyou-header {
    font-size: 3vw;
  }

  button.async-button {
    font-size: 3vw;
  }

  .piccle-result {
    width: 25%;
    height: auto;
  }

  .center-flex {
    width: 40vw;
  }

  .async-end-container {
    picture {
      width: 50vw;
      height: auto;
    }
  }
}

@media only screen and (max-width: $mobile-viewport) {
  .async-projection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 0;
    width: 100%;
    height: auto;
    overflow: auto;
  }

  h1.async-welcome-header {
    font-size: 1.2rem;
  }

  picture.async-welcome-image {
    width: 100vw;
  }

  h2.async-thankyou-header {
    font-size: 4vw;
  }

  button.async-button {
    font-size: 5vw;
  }

  .async-btn-group {
    width: 50vw;
  }

  .center-flex {
    width: 60vw;
  }

  .async-done-btn-container {
    display: flex;
    justify-content: center;

    button.async-button {
      font-size: 7vw;
      width: 40vw;
    }
  }

  .piccle-result {
    width: 35%;
  }

  .async-end-container {
    picture {
      width: 100vw;
      height: auto;
    }
  }
}
