@import '../variables/index.scss';

@media only screen and (max-width: $mobile-viewport) {
  .button {
    background: $piccle-green;
    color: white;
    border-radius: 20px;
    width: 5%;
    cursor: pointer;
    min-height: 2.25rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 0.875rem;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
    background: #8fd71e;
    border: 1px solid;
  }
}
